import React, { Component } from "react";
import "./SideBar.css";
import InfoBubble from "../InfoBubble/InfoBubble.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GoogleMapReact from "google-map-react";

class SideBar extends Component {
    constructor(props) {
        super(props);

        const mapCenter = props.coordinates || window.defaultMapCenter;
        const mapZoom = props.mapZoom || 3;

        this.mapRef = React.createRef();

        this.state = {
            keywords: props.keywords || "",
            location: props.location || "",
            mapCenter,
            mapBounds: null,
            mapZoom,
            mapsKey: window.localStorage.googleKey,
            unsavedChanges: false,
            keywordsChanged: false,
            locationChanged: false,
            advancedToggled: false,
            updated: false,
            mapQuerySet: false,
            mapObj: null,
            mapLoaded: false,
            markers: {},
            highlightedMarker: null,
            uploadedImageText: `
                The "Uploaded Image" is used for the image search.
                The search results are hotel rooms that our algorithms 
                believe are the closest matches to the uploaded image.`,
            locationText: `
                The "Location" parameter defines geographic bounds for 
                image search results. The returned images will only be 
                those taken within the specified map area.`,
            keywordText: `
                The "Keyword" parameter allows you to specify which words 
                to include or exclude in your search. Example: "Hilton" 
                would focus on Hilton hotels, while "NOT Hilton" would 
                exclude them.`,
        };
    }

    componentDidMount() {
        if (!this.props.locked) {
            this.initializeMap();
        } else {
            this.toggleAdvancedLoading("show");
        }

        // Potentially delete this?
        // was going to be used to expose this method to the parent component
        // but now I think it is only handled internally....
        if (this.props.setAddMarker) {
            this.props.setAddMarker(this.addMarker);
        }

        if (this.props.setToggleHighlightMarker) {
            this.props.setToggleHighlightMarker(this.toggleHighlightMarker);
        }

        if (this.props.searchResults) {
            this.updateMarkers(this.props.searchResults);
        }
    }

    componentDidUpdate(prevProps) {
        // Check if search results have changed
        if (
            prevProps.searchResults !== this.props.searchResults ||
            (prevProps.searchResults && this.props.searchResults && prevProps.searchResults.length !== this.props.searchResults.length)
        ) {
            this.updateMarkers(this.props.searchResults);
        }

        if (
            this.props.locked &&
            (this.props.keywords !== prevProps.keywords ||
                this.props.location !== prevProps.location ||
                this.props.coordinates !== prevProps.coordinates ||
                this.props.mapZoom !== prevProps.mapZoom) &&
            !this.state.updated
        ) {
            this.setState(
                {
                    keywords: this.props.keywords || this.state.keywords,
                    location: this.props.location || this.state.location,
                    mapCenter: this.props.coordinates || this.state.mapCenter,
                    mapZoom: this.props.mapZoom || this.state.mapZoom,
                    updated: true,
                },
                () => {
                    this.toggleAdvancedLoading("hide");
                    if (!this.state.mapObj) {
                        this.initializeMap();
                    }
                }
            );
        }
    }

    initializeMap = () => {

        if (!window.google || this.state.mapObj) return;
        
        const mapCenter = this.state.mapCenter || window.defaultMapCenter;
        const mapZoom = this.state.mapZoom || 3;
        const zoomControl = !this.props.locked;
    
        const map = new window.google.maps.Map(document.getElementById("map"), {
            center: { lat: mapCenter[0], lng: mapCenter[1] },
            zoom: mapZoom,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl,
            minZoom: 1,
        });
    
        window.map = map;
    
        window.google.maps.event.addListener(map, "bounds_changed", () => this.updateMapState(map));
        window.google.maps.event.addListener(map, "zoom_changed", () => this.updateMapState(map));
    
        this.setState({ mapObj: map });
    };

    updateMapState = (map) => {
        console.log("updating map state");
        if (!map.getBounds()) return;

        const bounds = {
            ne: [map.getBounds().getNorthEast().lat(), map.getBounds().getNorthEast().lng()],
            sw: [map.getBounds().getSouthWest().lat(), map.getBounds().getSouthWest().lng()],
        };

        const center = [parseFloat(map.getCenter().lat().toFixed(4)), parseFloat(map.getCenter().lng().toFixed(4))];
        const zoom = map.getZoom();

        const unsavedChanges =
            Math.abs(center[0] - this.state.mapCenter[0]) > 0.1 ||
            Math.abs(center[1] - this.state.mapCenter[1]) > 0.1 ||
            this.state.mapZoom !== zoom;

        // Avoid unnecessary state updates!
        if (
            JSON.stringify(bounds) === JSON.stringify(this.state.mapBounds) &&
            zoom === this.state.mapZoom &&
            JSON.stringify(center) === JSON.stringify(this.state.mapCenter) &&
            unsavedChanges === this.state.unsavedChanges
        ) {
            return;
        }

        this.setState({ 
            mapBounds: bounds, 
            mapZoom: zoom, 
            mapCenter: center, 
            unsavedChanges: this.state.unsavedChanges || unsavedChanges 
        });
    };

    updateMarkers = (searchResults) => {
    
        // Clear existing markers
        Object.values(this.state.markers).forEach(marker => marker.setMap(null));
    
        // Instead of directly calling `setState({ markers: {} })`, use a function
        this.setState({ markers: {} }, () => {
            searchResults.forEach(result => {
                this.addMarker(result.lat, result.lng, result.hotel_id, result.id, result.score);
            });
        });
    };

    addMarker = (lat, lng, hotel_id, result_id, score) => {
        if (!this.state.mapObj) return;
    
        this.setState((prevState) => {
            const updatedMarkers = { ...prevState.markers };
    
            if (updatedMarkers[hotel_id]) {
                // Compare scores and update result_id if the new score is higher
                if (score > updatedMarkers[hotel_id].score) {
                    updatedMarkers[hotel_id].result_id = result_id;
                    updatedMarkers[hotel_id].score = score;
                }
            } else {
                // Create a new marker
                const marker = new window.google.maps.Marker({
                    position: { lat, lng },
                    map: this.state.mapObj,
                    icon: "http://maps.google.com/mapfiles/ms/icons/red-dot.png",
                    hotel_id,
                    result_id,
                    score,
                });

                // This keeps highlighted markers consistent when results are updated.
                if (this.state.highlightedMarker) {
                    if (this.state.highlightedMarker.hotel_id == marker.hotel_id) {
                        // If this marker is the highlighted one, set its icon to blue
                        marker.setIcon({
                            url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png",
                            scaledSize: new window.google.maps.Size(50, 50) // Make it larger
                        });
                        marker.setZIndex(google.maps.Marker.MAX_ZINDEX + 1); // Bring to forefront
                    }
                }
    
                // Add click event listener to the marker
                marker.addListener("click", () => {
                    this.props.toggleModal(result_id, hotel_id);
                });
    
                updatedMarkers[hotel_id] = marker;
            }
    
            return { markers: updatedMarkers };
        }, () => {
            console.log("markers updated");
        });
    };

    setSelectedMarker = (hotel_id, result_id) => {
        const { markers, highlightedMarker } = this.state;
    
        // Un-highlight the previously highlighted marker
        if (highlightedMarker && markers[highlightedMarker.hotel_id]) {
            markers[highlightedMarker.hotel_id].setIcon({
                url: "http://maps.google.com/mapfiles/ms/icons/red-dot.png", // Reset to red
                scaledSize: new window.google.maps.Size(32, 32) // Reset size
            });
            markers[highlightedMarker.hotel_id].setZIndex(google.maps.Marker.MAX_ZINDEX - 1); // Send to background
        }
    
        // If unhighlighting (hotel_id is null), clear the state and return
        if (!hotel_id) {
            this.setState({ highlightedMarker: null });
            return;
        }
    
        // Highlight the new marker
        if (markers[hotel_id]) {
            markers[hotel_id].setIcon({
                url: "http://maps.google.com/mapfiles/ms/icons/blue-dot.png", // Change to blue
                scaledSize: new window.google.maps.Size(50, 50) // Make it larger
            });
            markers[hotel_id].setZIndex(google.maps.Marker.MAX_ZINDEX + 1); // Bring to forefront
        }
    
        // Update state with both hotel_id and result_id
        this.setState({ highlightedMarker: { hotel_id, result_id } });
    };

    toggleHighlightMarker = (hotel_id, result_id) => {
        const { highlightedMarker } = this.state;

        console.log("Toggling highlight for hotel_id:", hotel_id, "result_id:", result_id);
    
        if (highlightedMarker) {
    
            // If clicking the same hotel & result, un-highlight
            if (highlightedMarker.hotel_id == hotel_id && highlightedMarker.result_id == result_id) {
                console.log("Unhighlighting marker for hotel_id:", hotel_id);
                this.setSelectedMarker(null, null);
                return;
            }
        }
    
        // Allow clicking a different result from the same hotel (update state)
        this.setSelectedMarker(hotel_id, result_id);
    };

    updateSearch = () => {
        document.getElementById("update-search-text").style.display = "none";
    
        const keywords = document.getElementById("keywords-input").value;
        const location = document.getElementById("location-input").value;
    
        this.props.updateSearch({
            keywords,
            location,
            coordinates: this.state.mapCenter,
            bounds: this.state.mapBounds,
            zoom: this.state.mapZoom,
        });
    
        this.setState({
            unsavedChanges: false,
            keywordsChanged: false,
            locationChanged: false,
            keywords,
            location,
            advancedToggled: true,
        });
    };
    

    toggleAdvancedLoading = (action) => {
        const method = action === "show" ? "add" : "remove";
        const loadingWrapper = document.getElementById("advanced-options-loading-wrapper");
        const innerWrapper = document.getElementById("advanced-options-inner-wrapper");
    
        if (loadingWrapper) loadingWrapper.classList[method]("display");
        if (innerWrapper) innerWrapper.classList[method]("hide");
    };

    updateMap = async (query) => {
        if (!query) return;
    
        const urlFormattedQuery = encodeURIComponent(query);
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?address=${urlFormattedQuery}&key=${this.state.mapsKey}`
            );
    
            if (!response.ok) throw new Error("Failed to fetch location data");
    
            const data = await response.json();
            if (data.status === "OK") {
                const newCoords = data.results[0].geometry.location;
                const newCoordsArray = [newCoords.lat, newCoords.lng];
    
    
                if (this.state.mapObj) {
                    this.state.mapObj.setCenter({ lat: newCoords.lat, lng: newCoords.lng });
                }

                document.getElementById("location-input").value = "";
    
                this.setState({
                    mapCenter: newCoordsArray,
                    location: "",
                    mapZoom: 9,
                    mapQuerySet: true,
                    locationChanged: false,
                    unsavedChanges: true,
                }, () => {
                    this.updateMapState(this.state.mapObj); 
                });
    
            } else if (data.status === "ZERO_RESULTS") {
                alert(`Unable to find location "${query}"`);
                this.setState({ locationChanged: false });
            } else {
                throw new Error(`Geocoding API error: ${data.status}`);
            }
        } catch (error) {
            alert("Unable to update map. Please try again.");
            this.setState({ locationChanged: false });
        }
    };

    render() {
        const { keywordsChanged, locationChanged, location, mapCenter, unsavedChanges } = this.state;
        const { locked, resultsPage, resetSearch, resetSearchFunc, imgUrl, imgStyles } = this.props;
        let lockMapClass = this.props.locked ? 'disabled' : '';
            
        return (
            <div className="sidebar" id="sidebar">
                {resetSearch && (
                    <div className="reset-search" onClick={resetSearchFunc}>
                        <FontAwesomeIcon icon="redo-alt" /> Reset Search
                    </div>
                )}
    
                {imgUrl && (
                    <div className="uploaded-image option">
                        <div className="uploaded-image-box">
                            <span>Uploaded Image</span>
                            <InfoBubble direction="bottom">{this.state.uploadedImageText}</InfoBubble>
                            <span>
                                <div className="uploaded-entry-wrapper" id="uploaded-entry-wrapper">
                                    <img
                                        src={imgUrl}
                                        alt="Uploaded preview"
                                        className="uploaded-entry"
                                        id="uploaded-entry-sb"
                                        style={imgStyles}
                                        onLoad={() => {
                                            const sidebarImage = document.getElementById("uploaded-entry-sb");         
                                            sidebarImage.classList.add("show");            
                                        }}
                                    />
                                </div>
                            </span>
                        </div>
                        <hr className="image-sb-br" />
                    </div>
                )}
    
                <div id="advanced-options" className="display">
                    <span id="advanced-options-inner-wrapper">
                        <div id="set-keywords" className="option">
                            Search Keywords
                            <InfoBubble direction="right" text={this.state.keywordText} />
                            <input
                                type="text"
                                className="keywords-input"
                                id="keywords-input"
                                placeholder="Enter Search Terms"
                                value={this.state.keywords}
                                onChange={(e) => this.setState({ keywordsChanged: true, keywords: e.target.value })}
                                disabled={locked}
                            />
                        </div>
                        <div id="set-location" className="option">
                            Search Location
                            <InfoBubble direction="right" text={this.state.locationText} />
                            <input
                                type="text"
                                className="keywords-input"
                                id="location-input"
                                placeholder="Enter Location"
                                value={location}
                                onChange={(e) => this.setState({ locationChanged: true, location: e.target.value })}
                                disabled={locked}
                            />
                            {locationChanged && (
                                <div className="update-map-btn-wrapper">
                                    <div className="update-map-btn" onClick={() => this.updateMap(location)}>
                                        Update Map
                                    </div>
                                </div>
                            )}
                            <div className={`set-location-inner ${lockMapClass}`} style={{ height: "200px", width: "100%" }}>
                                <div className="map-contents" id="map"></div>
                            </div>
                        </div>
                        
                        {unsavedChanges && resultsPage && !locked && (
                            <button className="btn-primary" onClick={this.updateSearch}>
                                Update Search
                            </button>
                        )}
                    </span>
                </div>
            </div>
        );
    }
}

export default SideBar;