import React, {
    Component
} from 'react';
import './SearchPage.css';

import PageBuilder from '../../interfaces/PageBuilder.js';
import SideBar from '../../components/SideBar/SideBar.js';
import EditPane from '../../components/EditPane/EditPane.js';

import CanvasDraw from "react-canvas-draw";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';

import {
    ResultsPage
} from '../ResultsPage/ResultsPage.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBubble from '../../components/InfoBubble/InfoBubble';

import { v4 as uuidv4 } from 'uuid';
import EditSection from '../../components/EditSection/EditSection';

class SearchPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            // imgUrl: '/img/placeholder_upload_wbg.svg',
            imgUrl: null,
            imgValue: null,
            sidebarImgUrl: '/img/sidebar_placeholder.png',
            maskImgUrl: null,
            currentPage: 'startSearch',
            coordinates: window.defaultMapCenter,
        }
    }

    handleStart(e) {
        document.getElementById('upload-drop-area').classList.add('over');
        document.getElementById('upload-img-btn').classList.add('hide');
        document.getElementById('drop-file-label').classList.add('show');
        e.preventDefault();
        e.stopPropagation();
    }

    handleEnd(e) {
        document.getElementById('upload-drop-area').classList.remove('over');
        document.getElementById('upload-img-btn').classList.remove('hide');
        document.getElementById('drop-file-label').classList.remove('show');
        e.preventDefault();
        e.stopPropagation();
    }

    searchWithoutImage() {
        this.setState({ currentPage: 'showResults' });
    }

    render() {

        let pageContents = '';
        if (this.state.currentPage === 'startSearch') {
            let uploadText = `
                .png, .jpeg, .jpg, .tif, and .tiff are the only filetypes
                allowed for upload`;
            pageContents = (
                <div className="search-page-content" id="search-page-content">
                    <SideBar imgUrl={null}
                        resultsPage={false}
                        startSearch = {true}
                        coordinates={this.state.coordinates}
                    />
                    <div className="interaction-pane" id="interaction-pane"
                        onDragEnter={(e) => {
                            this.handleStart(e);
                        }}

                        onDragOver={(e) => {
                            this.handleStart(e);
                        }}

                        onDrag={(e) => {
                            this.handleStart(e);
                        }}

                        onDragLeave={(e) => {
                            this.handleEnd(e);
                        }}

                        onDragEnd={(e) => {
                            this.handleEnd(e);
                        }}

                        onDrop={(e) => {
                            this.handleEnd(e);

                            if (e.dataTransfer.files.length === 1) {
                                this.imgChange(e.dataTransfer.files[0]);
                            } else if (e.dataTransfer.files.length > 1) {
                                alert('Only one image is allowed to be uploaded for search');
                            }

                        }}
                    >
                        <span className="italics" style={{ whiteSpace: 'nowrap' }}>
                            Begin your search by uploading an image below
                            <InfoBubble direction="bottom" text={uploadText} />
                        </span>
                        <div id="upload-drop-area">
                            <img src="/img/placeholder_upload_wbg.svg" alt=""
                                className="interaction-upload" id="mainImg" />
                            <input type="file" accept=".jpg,.png,.jpeg,.tiff,.tif"
                                id="imgupload" onChange={this.imgChange.bind(this)} />
                            <button className="btn-primary" id="upload-img-btn"
                                onClick={this.upload.bind(this)}
                            >
                                Upload Image
                            <FontAwesomeIcon icon="circle-notch"
                                    className="fa-spin" id="img-upload-load" />
                            </button>
                            <h1 className="drop-file-label" id="drop-file-label">
                                Drop image here
                            </h1>
                            <button className="btn-alt" id="no-img-search-btn"
                                onClick={() => { this.searchWithoutImage() }}>
                                Search Without Image
                            <span id="loadingsearch">
                                    <FontAwesomeIcon icon="circle-notch"
                                        className="fa-spin load-search" />
                                </span>
                            </button>
                        </div>

                    </div>
                </div>);
        } else if (this.state.currentPage === 'editImage') {
            let location = document.getElementById('location-input').value;
            let keywords = document.getElementById('keywords-input').value;

            let centerObj = window.map.getCenter();
            let coordinates = [parseFloat(centerObj.lat().toFixed(4)), parseFloat(centerObj.lng().toFixed(4))];

            let boundsObj = window.map.getBounds();
            let bounds = {
                'ne': [parseFloat(boundsObj.getNorthEast().lat()), parseFloat(boundsObj.getNorthEast().lng())],
                'sw': [parseFloat(boundsObj.getSouthWest().lat()), parseFloat(boundsObj.getSouthWest().lng())],
            }

            let mapZoom = window.map.getZoom();

            let advancedSidebar = [...document.getElementById('advanced-options').classList].includes('display');

            pageContents = (
                <div className="search-page-content scroll" id="search-page-content">
                    <SideBar imgUrl={null}
                        resetSearch={true}
                        resetSearchFunc={this.resetSearch.bind(this)}
                        keywords={keywords}
                        coordinates={coordinates}
                        location={location}
                        resultsPage={false}
                        mapZoom={mapZoom}
                    />

                    <EditSection imgUrl={this.state.imgUrl} imgValue={this.state.imgValue}
                        interactionPane={true}
                        changePage={(newPage, sidebarImgUrl = null, imgUrl = null, imgValue = null, maskImgUrl = null) => {

                            if (sidebarImgUrl && imgUrl && imgValue) {
                                this.setState({
                                    currentPage: newPage,
                                    imgUrl: imgUrl,
                                    imgValue: imgValue,
                                    maskImgUrl: maskImgUrl
                                });
                            } else {
                                this.setState({ currentPage: newPage });
                            }
                        }} />
                </div>);
        } else if (this.state.currentPage === 'showResults') {
            let location = document.getElementById('location-input').value;

            // This currently fetches keywords and concatenates them into a comma separated string
            const keywordsList = Array.from(document.querySelectorAll('.keyword-bubble'))
                      .map(bubble => bubble.childNodes[1].textContent.trim());
            console.log("RETRIEVING KEYWORDS LIST");
            console.log(keywordsList);
            const keywordsString = keywordsList.join(',');
            let centerObj = window.map.getCenter();
            let coordinates = [parseFloat(centerObj.lat().toFixed(4)), parseFloat(centerObj.lng().toFixed(4))];

            let boundsObj = window.map.getBounds();
            let bounds = {
                'ne': [parseFloat(boundsObj.getNorthEast().lat()), parseFloat(boundsObj.getNorthEast().lng())],
                'sw': [parseFloat(boundsObj.getSouthWest().lat()), parseFloat(boundsObj.getSouthWest().lng())],
            }

            let mapZoom = window.map.getZoom();

            let advancedSidebar = [...document.getElementById('advanced-options').classList].includes('display');

            //~ let searchID = uuidv4();
            let sID = null;
            let resultsUrl = `results/`;
            window.history.pushState({}, 'Results Page', 'results/');

            return (
                <ResultsPage
                    sidebarImgUrl={this.state.imgUrl}
                    advancedSidebar={advancedSidebar}
                    imgUrl={this.state.imgUrl}
                    imgValue={this.state.imgValue}
                    maskImgUrl={this.state.maskImgUrl}
                    brightness={this.state.brightness}
                    saturation={this.state.saturation}
                    contrast={this.state.contrast}
                    location={location}
                    coordinates={coordinates}
                    keywords={keywordsString}
                    keywordsList={keywordsList}
                    mapBounds={bounds}
                    mapZoom={mapZoom}
                    searchID= {sID}
                    urlAccessed={false}
                />);
        }
        return pageContents;
    }

    resetSearch() {
        this.setState({
            currentPage: 'startSearch',
            maskEnabled: true,
            cropEnabled: false,
            moreEnabled: false,
            brightness: 100,
            contrast: 100,
            saturation: 100
        });
    }

    imgChange(passedFile = null) {
        document.getElementById('img-upload-load').classList.toggle('show');
        let input = document.getElementById('imgupload');
        if ((input.files && input.files[0]) || passedFile) {
            var reader = new FileReader();

            reader.onload = (e) => {
                let im = new Image();
                im.onload = () => {
                    var canvas = document.createElement("canvas");
                    var ctx = canvas.getContext("2d");
                    ctx.drawImage(im, 0, 0);

                    let imgWrapper = document.getElementById('interaction-pane');

                    var MAX_WIDTH = 912;
                    var MAX_HEIGHT = 812;
                    var width = im.width;
                    var height = im.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;
                    ctx = canvas.getContext("2d");
                    ctx.drawImage(im, 0, 0, width, height);
                    var dataurl = canvas.toDataURL("image/png");

                    im.width = width;
                    im.height = height;

                    document.getElementById('img-upload-load').classList.toggle('show');
                    this.setState({
                        imgUrl: dataurl,
                        imgValue: im,
                        sidebarImgUrl: '/img/sidebar_placeholder.png',
                        currentPage: 'editImage'
                    });

                }
                im.onerror = () => {
                    alert("Unable to upload image. Please try again.");
                    document.getElementById('img-upload-load').classList.toggle('show');
                }
                im.src = e.target.result;
            }

            if (input.files && input.files[0]) {
                reader.readAsDataURL(input.files[0]);
            } else {
                reader.readAsDataURL(passedFile);
            }
        }
    }

    upload() {
        var simulateClick = function (elem) {

            // Create our event (with options)
            let evt = new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            });
            elem.dispatchEvent(evt);
        };

        simulateClick(document.getElementById('imgupload'));
        return;
    }
}

class SearchPageBuilder extends PageBuilder {

    async onPageLoad() {
        return;
    }
    // @override
    pageContent(hotel = false) {
        return (
            <SearchPage />
        );
    }

}

export {
    SearchPage,
    SearchPageBuilder
};
