import React, { Component } from 'react';
import './EditSection.css';

import EditPane from '../../components/EditPane/EditPane.js';

import CanvasDraw from "react-canvas-draw";
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfoBubble from '../../components/InfoBubble/InfoBubble';
import { element } from 'prop-types';
import { Container, Row, Col } from 'react-grid-system';

class EditSection extends Component {

    constructor(props) {
        super(props);
        this.props = props;

        this.state = {
            imgUrl: props.imgUrl,
            imgValue: props.imgValue,
            sidebarImgUrl: props.imgUrl,
            maskPaneEnabled: true,
            cropEnabled: false,
            filterPaneEnabled: false,
            editPaneEnabled: false,
            crop: null,
            brushRadius: 30,
            croppedImageUrl: null,
            maskUrl: null,
            brightness: 100,
            contrast: 100,
            saturation: 100,
            croppedUrl: null,
            croppedValue: null,
            previousCropBoxData: null,
            imgRotation: 0,
            brushColor: 'white',
            //~ brushColor: this.getPattern(),
            cropListenersSet: false
        }
    }

    componentDidUpdate() {
        /* Need to experiement with the placement of this cropping code.
         * we're checking to see if we need to create a new cropper/crop-box/listeners/handlers.
         * It would be more efficient to only do this when we have specifically asked
         * to create a new cropper. Left here for now bc it finally works. Edit at your own risk.
         */

        if (this.state.cropListenersSet) {
            return;
        } else if (this.state.cropEnabled) {
            makeResizableDiv('.crop-overlay');
            this.setState({ cropListenersSet: true })
        }

        function makeResizableDiv(div) {
            // CHANGE: element and crop canvas are referring to the same thing!!
            const element = document.querySelector(div);
            const resizers = document.querySelectorAll(div + ' .crop-over-circle');
            const cropCanvas = document.getElementById('crop-overlay').getBoundingClientRect();
            const wrapper = document.getElementById('draw-canvas').getBoundingClientRect();

            let maximum_height = wrapper.height;
            let  maximum_width = wrapper.width;

            const far_left = wrapper.left;
            const far_right = wrapper.right;
            const high_top = wrapper.top;
            const low_bottom = wrapper.bottom;

            const minimum_size = 20;

            // declare variables for click & drag crop box changes
            let original_width = 0;
            let original_height = 0;

            let original_left = 0;
            let original_top = 0;
            let original_right = 0;
            let original_bottom = 0;

            // Mouse-down start position
            let original_mouse_x = 0;
            let original_mouse_y = 0;


            for (let i = 0; i < resizers.length; i++) {
                const currentResizer = resizers[i];
                currentResizer.addEventListener('mousedown', function (e) {
                    e.preventDefault()
                    original_width = parseFloat(getComputedStyle(element, null).getPropertyValue('width').replace('px', ''));
                    original_height = parseFloat(getComputedStyle(element, null).getPropertyValue('height').replace('px', ''));
                    original_left = element.getBoundingClientRect().left;
                    original_top = element.getBoundingClientRect().top;
                    original_right = element.getBoundingClientRect().right;
                    original_bottom = element.getBoundingClientRect().bottom;
                    original_mouse_x = e.pageX;
                    original_mouse_y = e.pageY;
                    window.addEventListener('mousemove', resize)
                    window.addEventListener('mouseup', stopResize)
                })

                function resize(e) {
                    let imgCanvas = document.getElementsByClassName("canvas-draw")[0].children[0];

                    if (currentResizer.classList.contains('bottom') && currentResizer.classList.contains('right')) {
                        let width = original_width + (e.pageX - original_mouse_x);
                        let height = original_height + (e.pageY - original_mouse_y);

                        width = width > (imgCanvas.width - 1) ? (imgCanvas.width - 1) : width;
                        height = height > (imgCanvas.height - 1) ? (imgCanvas.height - 1) : height;
                        let newRight = far_right - e.pageX;
                        let newBottom = low_bottom - e.pageY;

                        
                        if (width > minimum_size && newRight >= 0) {
                            element.style.width = width + 'px'
                            element.style.right = newRight + 'px'
                        }

                        if (height > minimum_size && newBottom >= 0) {
                            element.style.height = height + 'px'
                            element.style.bottom = newBottom + 'px'
                        }
                    }
                    else if (currentResizer.classList.contains('bottom') && currentResizer.classList.contains('left')) {
                        let height = original_height + (e.pageY - original_mouse_y);
                        let width = original_width - (e.pageX - original_mouse_x);

                        let newLeft = e.pageX - far_left;
                        let newBottom = low_bottom - e.pageY;

                        width = width > (imgCanvas.width - 1) ? (imgCanvas.width - 1) : width;
                        height = height > (imgCanvas.height - 1) ? (imgCanvas.height - 1) : height;

                        if (height > minimum_size && newBottom >= 0) {
                            element.style.height = height + 'px'
                            element.style.bottom = newBottom + 'px'
                        }

                        if (width > minimum_size && newLeft >= 0) {
                            element.style.width = width + 'px'
                            element.style.left = newLeft + 'px'
                        }
                    }
                    else if (currentResizer.classList.contains('top') && currentResizer.classList.contains('right')) {
                        let width = original_width + (e.pageX - original_mouse_x);
                        let height = original_height - (e.pageY - original_mouse_y);

                        // reset relevant crop overlay boundaries.
                        // original_right: previous crop overlay right boundary. Changes when crop overlay moves.
                        let newRight = far_right - e.pageX;
                        let newTop = e.pageY - high_top;

                        // Trigger default maximum values if we exceed them
                        width = width > (imgCanvas.width - 1) ? (imgCanvas.width - 1) : width;
                        height = height > (imgCanvas.height - 1) ? (imgCanvas.height - 1) : height;

                        // Update width & right boundary
                        if (width > minimum_size && newRight >= 0) {
                            element.style.width = width + 'px'
                            element.style.right = newRight + 'px'
                        }
                        
                        // Update height and top boundary.
                        // element.style.top is space between the outer overlay wrapper top and the crop overlay top
                        if (height > minimum_size && newTop >= 0) {
                            element.style.height = height + 'px';
                            element.style.top = newTop  + 'px';
                        }
                    }
                    else if (currentResizer.classList.contains('top') && currentResizer.classList.contains('left')) {
                        let width = original_width - (e.pageX - original_mouse_x);
                        let height = original_height - (e.pageY - original_mouse_y);

                        width = width > (imgCanvas.width - 1) ? (imgCanvas.width - 1) : width;
                        height = height > (imgCanvas.height - 1) ? (imgCanvas.height - 1) : height;

                        let newLeft = e.pageX - far_left;
                        let newTop = e.pageY - high_top;

                        if (width > minimum_size && newLeft >= 0) {
                            element.style.width = width + 'px';
                            element.style.left = newLeft + 'px'
                        }

                        if (height > minimum_size && newTop >= 0) {
                            element.style.height = height + 'px';
                            element.style.top = newTop + 'px';

                        }
                    }
                }

                function stopResize() {
                    window.removeEventListener('mousemove', resize);
                }
            }

        }

        // consider re-locating or refactoring this.
        // currently main advantage is we can target just the canvas
        // we want, and not mess with the mask drawings.
        if (this.state.filterPaneEnabled) {
            let imgCanvas = document.getElementsByClassName("canvas-draw")[0].children[0];
            imgCanvas.style.filter = `brightness(${this.state.brightness}%)
                                         contrast(${this.state.contrast}%)
                                         saturate(${this.state.saturation}%)`;
        }

        
    }

    render() {

        if (!(this.state.imgValue)) {
            let newImg = new Image();
            newImg.crossOrigin = undefined;
            newImg.src = this.state.imgUrl;
            newImg.onload = () => {
                this.setState({
                    imgValue: { width: newImg.width, height: newImg.height }
                })
            };

            let interactionPaneClass = this.props.interactionPane ? "interaction-pane" : "";

            if (this.props.overlay) {
                interactionPaneClass += ' overlay';
            }

            let pageContents = (
                <div className={interactionPaneClass}>
                    loading
                </div>);

            return pageContents;
        } else {
            var uploadedImage = this.state.imgValue;
            var imageWidth = uploadedImage.width;
            var imageHeight = uploadedImage.height;

            let imgMaxWidth = 912;
            let imgMaxHeight = 812;
            let widthRatio, heightRatio;

            /* this is triggered whenever we re-draw the image on the
             * canvas. Don't re-size if re-draw is bc of image rotation.
             * It can cause a bug where the image shrinks a little bit on
             * each rotation
             */
            if (this.state.imgRotation === 0){
                // if the image is too wide AND too tall
                if (imageWidth > imgMaxWidth && imageHeight > imgMaxHeight) {
                    // shrink the larger of the two to max dimension size, and scale the other accordingly
                    if (imageWidth > imageHeight) {
                        widthRatio = imageWidth/imgMaxWidth;
                        imageWidth = imgMaxWidth;
                        imageHeight = imageHeight * widthRatio;
                    } else {
                        heightRatio = imgMaxHeight / imageHeight;
                        imageHeight = imgMaxHeight;
                        imageWidth = imageWidth * heightRatio;
                    }
                }

                // if it's too wide
                if (imageWidth > imgMaxWidth) {
                    widthRatio = imgMaxWidth / imageWidth;
                    imageWidth = imgMaxWidth;
                    imageHeight = imageHeight * widthRatio;
                    // if it's too tall
                } else if (imageHeight > imgMaxHeight) {
                    heightRatio = imgMaxHeight / imageHeight;
                    imageHeight = imgMaxHeight;
                    imageWidth = imageWidth * heightRatio;
                }
            }

            this.imageWidth = imageWidth;
            this.imageHeight = imageHeight;

            this.fullImageWidth = uploadedImage.width;
            this.fullImageHeight = uploadedImage.height;


            let displayCrop = this.state.cropEnabled ? 'show' : 'hide';

            let interactionContents = (
                <span id="draw-canvas" >
                    <div className={`crop-overlay ${displayCrop}`}
                        id="crop-overlay"
                        style={{
                            'height': imageHeight,
                            'width': imageWidth
                        }}
                    >
                        <div className="crop-over-circle top left"></div>
                        <div className="crop-over-circle top right"></div>
                        <div className="crop-over-circle bottom left" ></div>
                        <div className="crop-over-circle bottom right"
                            id="crop-circle-bottom-right"></div>

                        <div className="guiding-box-horizontal"></div>
                        <div className="guiding-box-vertical">
                            <div className="crop-center-align unselectable">+</div>
                        </div>
                    </div>
                    <span id="canvas-wrapper">
                        <CanvasDraw
                            ref={canvasDraw => (this.saveableCanvas = canvasDraw)}
                            lazyRadius={0}
                            brushRadius={this.state.brushRadius}
                            brushColor={this.state.brushColor}
                            catenaryColor={this.state.brushColor}
                            hideGrid={true}
                            imgSrc={this.state.imgUrl}
                            canvasWidth={imageWidth}
                            canvasHeight={imageHeight}
                            disabled={!this.state.maskPaneEnabled}
                            saveData={null}
                            className={`canvas-draw ${this.state.maskPaneEnabled ? "mask-toggled" : ""}`}
                            style={{
                                //~ alternate form of setting img below -- doesn't draw the img 'in' a html canvas
                                //~ backgroundImage: `url(${this.state.imgUrl})`,
                                backgroundSize: `${imageWidth}px ${imageHeight}px`,
                                // this is one way of getting the filters to work
                                // see the end of componentDidUpdate for current method
                                //~ filter: `brightness(${this.state.brightness}%)
                                         //~ contrast(${this.state.contrast}%)
                                         //~ saturate(${this.state.saturation}%)`
                            }}
                        />
                    </span>
                    <canvas id="out-canvas"></canvas>
                    <canvas id="mask-canvas"></canvas>
                </span>
            );

            let formatText = `
                    To proceed with the image search, you will need to mask off any
                    areas of the photograph that show the victim or other sensitive
                    content. Use the eraser tool below to remove any parts of the
                    image that aren’t a part of the hotel room (e.g. the entire
                    victim and any luggage or other items in the room). You may also
                    crop your image and adjust various attributes of the image's
                    style to produce a better image of the hotel.`;

            let interactionPaneClass = this.props.interactionPane ? "interaction-pane" : "";

            if (this.props.overlay) {
                interactionPaneClass += ' overlay';
            }

            let pageContents = (
                <Container fluid className={interactionPaneClass}>
                    <div className="interaction-pane-label">
                        <div id="interaction-pane-label-text">
                            Format Image
                            </div>
                        <InfoBubble direction="right" text={formatText} />
                    </div>
                    <Row style={{ flexWrap: 'nowrap' }}>
                        <Col xs={3} className="edit-pane-col">
                            <EditPane
                                onEditComplete={this.completeEdit.bind(this)}
                                rotateCallback={this.rotateUpload.bind(this)}
                                cropCallback={this.toggleCrop.bind(this)}
                                maskPaneCallback={this.toggleMaskPane.bind(this)}
                                filterPaneCallback={this.toggleFilterPane.bind(this)}
                                editPaneCallback={this.toggleEditPane.bind(this)}
                                undoEdit={this.undoEdit.bind(this)}
                                clearEdit={() => {
                                    this.clearEdit();
                                }}
                                executeSearch={() => {

                                    let spinner = document.getElementById('loadingsearch');

                                    spinner.style.display = 'inline-block';
                                    this.executeSearch();
                                }}
                                //dont think this does anything. cant find props.updateSearch
                                updateSearch={this.props.updateSearch}

                                brushSize={this.state.brushRadius}
                                cropEnabled={this.state.cropEnabled}
                                updateBrush={(newVal) => { this.setState({ brushRadius: newVal }) }}

                                updateBrightness={(newVal) => { this.setState({ brightness: newVal }) }}
                                updateContrast={(newVal) => { this.setState({ contrast: newVal }) }}
                                updateSaturation={(newVal) => { this.setState({ saturation: newVal }) }}

                                mask={true} />
                        </Col>
                        <Col xs={9} className="interaction-col">
                            <div id="interaction-upload-wrapper" style={{ transform: `rotate(90deg) !important` }}>
                                {interactionContents}
                            </div>
                        </Col>
                    </Row>
                </Container>);

            return pageContents;
        }
    }

    getPattern() {
        var patternCanvas = document.createElement('canvas'),
            squareWidth = 20,
            patternCtx = patternCanvas.getContext('2d');

        patternCanvas.width = patternCanvas.height = 2 * squareWidth;

        patternCtx.fillStyle = 'LightGray';
        patternCtx.fillRect(0, 0, squareWidth, squareWidth);
        patternCtx.fillRect(squareWidth, squareWidth, squareWidth, squareWidth);
        patternCtx.fillStyle = 'white';
        patternCtx.fillRect(squareWidth, 0, squareWidth, squareWidth);
        patternCtx.fillRect(0, squareWidth, squareWidth, squareWidth);
        //~ patternCtx.fill();
        
        return patternCtx.createPattern(patternCanvas, 'repeat');
    }
    
    drawImage() {
        var canvas = document.getElementById('mainCanvas');
        var ctx = canvas.getContext('2d');

        var img = new Image();
        img.onload = function () {
            ctx.drawImage(img, 0, 0); // Or at whatever offset you like
        };
        img.src = this.state.imgUrl;
    }

    undoEdit() {
        this.saveableCanvas.undo();
    }

    clearEdit() {
        this.saveableCanvas.clear();
    }

    rotateUpload() {
        /* NOTE: react-canvas-draw <CanvasDraw> component will make 4 layered
         * canvases, they appear in the rendered DOM in this order:
         * 0) brush cursor
         * 1) current brushstroke
         * 2) previous brushstrokes
         * 3) uploaded image
         *
         * This function rotates canvas 3 while leaving the rest in place.
         */

        //this doesn't actually rotate anything. Using it
        // to record rotations and prevent automatic img resizing above.
        this.setState({ imgRotation: this.state.imgRotation + 90 });

        //re-size the canvas, swapping width and height.
        //this will automatically erase the contents of the canvas
        let temp_width = this.state.imgValue.width;
        let temp_height = this.state.imgValue.height;
        
        this.setState({
                    imgValue: { width: temp_height, height: temp_width }
                });

        //select the canvas element containing the uploaded img
        let imgCanvas = document.getElementsByClassName("canvas-draw")[0].children[0]
        let imgCanvasContext = imgCanvas.getContext('2d')

        //make a temp copy of current img canvas
        var rotatedImg = new Image()
        var tempImgSrc = imgCanvas.toDataURL();

        /* Redraw the image. Translation and rotation
         * have to happen inside this onload func or it won't
         * work properly. Set context origin to top right corner
         * and rotate 90 before draw. Restore origin to top
         * left when finished.
         */
        rotatedImg.onload = function () {
            imgCanvasContext.save();
            imgCanvasContext.translate(imgCanvas.width, 0);
            imgCanvasContext.rotate(90 * Math.PI / 180);
            imgCanvasContext.drawImage(rotatedImg, 0, 0);
            imgCanvasContext.restore();
        };
        rotatedImg.src = tempImgSrc;

        //clear previous mask. It hasn't been rotated.
        this.clearEdit();

        //reset crop overlay positions to default
        const cropper = document.getElementById('crop-overlay');
        cropper.style.left = 0;
        cropper.style.top = 0;
        cropper.style.right = 0;
        cropper.style.bottom = 0;
        
        //reset crop listeners to capture roated dimensions
        this.setState({ cropListenersSet: false })
    }

    toggleCrop(action) {

        let hideOnCrop = document.getElementsByClassName("hide-on-crop");
        let showOnCrop = document.getElementsByClassName("show-on-crop");
        // some buttons are disabled when cropEnabled is true. This is done
        // directly in the button element declaration.
        
        if (action === "start") {
            this.setState({
                cropEnabled: true
            });
            
            // set cropper to default position
            const cropper = document.getElementById('crop-overlay');
            let imgCanvas = document.getElementsByClassName("canvas-draw")[0].children[0];
            cropper.style.left = 0;
            cropper.style.top = 0;
            cropper.style.right = 0;
            cropper.style.bottom = 0;
            cropper.style.width = `${imgCanvas.width - 1}px`;
            cropper.style.height = `${imgCanvas.height - 1}px`;
            
            
            Array.prototype.forEach.call(hideOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            Array.prototype.forEach.call(showOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            
        } else if (action === "cancel") {
            this.setState({
                cropEnabled: false,
                cropListenersSet: false
            });
            Array.prototype.forEach.call(hideOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            Array.prototype.forEach.call(showOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });

        } else {
            // "finish" passed as an argument
            let cropper = document.getElementById('crop-overlay').getBoundingClientRect();
            let canvas_wrapper = document.getElementById('draw-canvas').getBoundingClientRect();
            let imgCanvas = document.getElementsByClassName("canvas-draw")[0].children[0];
            let imgCanvasContext = imgCanvas.getContext('2d');
            let drawingCanvas = document.getElementsByClassName("canvas-draw")[0].children[2];
            let drawingCanvasContext = drawingCanvas.getContext('2d');
            //~ let saveData = this.saveableCanvas.getSaveData();
            //~ let pattern = this.getPattern();

            this.saveableCanvas.clear();
            //~ this.saveableCanvas.loadSaveData(saveData, true);
            
            let imgObj = new Image();
            let tempImg = imgCanvas.toDataURL();

            imgObj.onload = function() {
                var sourceX = cropper.left - canvas_wrapper.left;
                var sourceY = cropper.top - canvas_wrapper.top;
                var sourceWidth = cropper.width;
                var sourceHeight = cropper.height;
                var destX = 0;
                var destY = 0;
                var destWidth = sourceWidth;
                var destHeight = sourceHeight;
                imgCanvasContext.drawImage(imgObj, sourceX, sourceY, sourceWidth, sourceHeight,
                    destX, destY, destWidth, destHeight);
                //~ drawingCanvasContext.strokeStyle = pattern;
            };

            imgCanvas.width = cropper.width;
            imgCanvas.height = cropper.height;

            canvas_wrapper.width = cropper.width;
            canvas_wrapper.height = cropper.height;

            this.setState({
                    imgUrl: tempImg,
                    // imgValue: { width: cropper.width, height: cropper.height },
                });

            imgObj.src = tempImg;

            //switch out of cropping mode
            this.setState({
                cropEnabled: false,
                cropListenersSet: false
            });
            Array.prototype.forEach.call(hideOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            Array.prototype.forEach.call(showOnCrop, function (item) {
                item.classList.toggle("hide-btn");
            });
            
        }
    }

    toggleEditPane() {
        this.setState({
            maskPaneEnabled: false,
            editPaneEnabled: true,
            filterPaneEnabled: false,
            brushColor: '#ffffff00',
        });
    }

    toggleMaskPane() {
        this.setState({
            maskPaneEnabled: true,
            editPaneEnabled: false,
            filterPaneEnabled: false,
            brushColor: 'white',
            //~ brushColor: this.getPattern(),
        });
    }

    toggleFilterPane() {
        this.setState({
            maskPaneEnabled: false,
            editPaneEnabled: false,
            filterPaneEnabled: true,
            brushColor: '#ffffff00',
        });
    }

    completeEdit() {
        let croppedImage = this.refs.cropper.getCroppedCanvas();
        let cropped = this.refs.cropper.getCroppedCanvas().toDataURL();
        this.setState({
            croppedImageUrl: cropped,
            croppedImageHeight: croppedImage.height,
            croppedImageWidth: croppedImage.width
        });
    }

    openDataUrl(url) {
        var iframe = `<iframe width='100%' height='100%' src='${url}'></iframe>`
        var x = window.open();
        x.document.open();
        x.document.write(iframe);
        x.document.close();
    }

    downloadDataUrl(uri) {
        var link = document.createElement("a");
        link.download = 'data';
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    executeSearch() {
        // Retrieve the canvas that is holding the original image
        let img = document.getElementsByClassName("canvas-draw")[0].children[0]
        let imgCanvas = document.getElementById('out-canvas');
        let imgCanvasContext = imgCanvas.getContext('2d');
        imgCanvasContext.canvas.width = img.width;
        imgCanvasContext.canvas.height = img.height;
        // Retrieve the mask only
        let drawing = this.saveableCanvas.canvas.drawing;
        let drawingContext = drawing.getContext('2d');
        
        imgCanvasContext.filter = `brightness(${this.state.brightness}%)
                                         contrast(${this.state.contrast}%)
                                         saturate(${this.state.saturation}%)`;
        // Draw the image onto a canvas, and mask it
        imgCanvasContext.drawImage(img, 0, 0)
        imgCanvasContext.filter = `none`;
        imgCanvasContext.drawImage(drawing, 0, 0);

        let outputUrl = imgCanvasContext.canvas.toDataURL();
        let maskUrl = drawingContext.canvas.toDataURL();

        // set page to show results, and pass output for sidebar image, and full image url
        this.props.changePage('showResults', outputUrl, outputUrl, this.state.imgValue, maskUrl);
        
    }
}

export default EditSection;
